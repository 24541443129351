<template>
  <modal
    name="shareRecordingModal"
    transition="pop-out"
    class="share-recording-modal"
    :width="500"
    :height="450"
  >
    <div class="position-relative modal-outer-container">
      <div class="modal-header border-bottom">
        <div>Share Recording</div>
        <button class="btn border-0" @click="closeModal">
          <i class="fas fa-times fa-lg"></i>
        </button>
      </div>
      <div class="body-inner-container">
        <div class="card-body">
          <form @submit.prevent="shareRecordingHelper" validate class="form">
            <div class="row">
              <div class="font-weight-bold col-12 mb-1">
                <input-control
                  v-model="title"
                  wrapClass="align-items-center"
                  type="vertical"
                  :required="true"
                  autofocus
                >
                  Shared Recording title
                </input-control>
              </div>
              <div class="col-12 mt-3 mt-md-0">
                <label class="col-12 font-weight-bold pt-2 pl-0">
                  Share Recording With
                </label>
                <div class="col-12 mb-1 px-0">
                  <multiselect
                    v-model="destinationUsers"
                    :options="getUsers"
                    :loading="loading"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Select the users to share the recording with"
                    Value="id"
                    label="name"
                    track-by="id"
                    :show-labels="false"
                    :limit="3"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="small col-12 mt-2">
                <b>Note:</b> Users with whom this recording was previously
                shared will not appear in the list above.
              </div>
            </div>
            <div class="text-center pt-4">
              <save :saving="shareRecordingLoading"> Share Recording </save>
            </div>
          </form>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import Swal from "sweetalert2";
import { mapActions, mapState } from "vuex";
import Multiselect from "vue-multiselect";
export default {
  name: "ShareRecordingModal",
  components: {
    Multiselect,
  },
  props: {
    recording: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      title: "",
      destinationUsers: [],
    };
  },
  mounted() {
    this.title = `${this.recording.meeting.room_name}_${this.recording.meeting.start_date}`;
  },
  watch: {
    recording: {
      handler: function (val) {
        this.title = `${val.meeting.room_name}_${val.meeting.start_date}`;
        this.destinationUsers = [];
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      loggedInUser: (state) => state.auth.user,
      allUsers: (state) => state.security.usersWithRoles,
      loading: (state) => state.security.usersWithRolesLoading,
      shareRecordingLoading: (state) => state.rooms.shareRecordingLoading,
    }),
    getUsers: function () {
      return this.allUsers
        .filter((user) => user.user_id != this.loggedInUser.id)
        .filter(
          (user) =>
            !this.recording.shared_with.find(
              (sharedUser) => sharedUser == user.user_id
            )
        )
        .map((user) => {
          return {
            id: user.user_id,
            name: user.name,
          };
        });
    },
  },
  methods: {
    ...mapActions({
      shareRecording: "rooms/shareRecording",
      updateSharedWithArray: "rooms/updateSharedWithArray",
    }),
    shareRecordingHelper() {
      if (!this.destinationUsers.length) {
        Swal.fire({
          title: "Error",
          text: "Please select at least one user to share the recording with",
          icon: "error",
        });
        return;
      }
      const destinationUsers = this.destinationUsers.map((user) => user.id);
      this.shareRecording({
        meetingId: this.recording.meeting.id,
        data: {
          share_title: this.title,
          recording_path: this.recording.path,
          destination_users: destinationUsers,
        },
      }).then((res) => {
        if (res) {
          Swal.fire({
            title: "Success",
            text: "Recording shared successfully",
            icon: "success",
          });
          const updatedSharedWith = [
            ...this.recording.shared_with,
            ...destinationUsers,
          ];
          this.updateSharedWithArray({
            path: this.recording.path,
            sharedWith: updatedSharedWith,
          });
          this.$emit("success-share", {
            recording: this.recording,
            updatedSharedWith,
          });
          this.title = `${this.recording.meeting.room_name}_${this.recording.meeting.start_date}`;
          this.destinationUsers = [];
          this.closeModal();
        }
      });
    },
    closeModal() {
      this.$modal.hide("shareRecordingModal");
    },
  },
};
</script>
<style lang="scss">
.share-recording-modal {
  &.v--modal-overlay {
    z-index: 1002;
  }
  .v--modal-box {
    overflow: visible !important;
  }
}
</style>
